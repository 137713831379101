import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Hub } from "aws-amplify";
import styles from "./styles/App.module.scss";
import Routes from "./Routes";
import Log from "./utils/Log";
import { logout, login } from "./utils/auth";
// import { saveCredentialsToSession } from "./utils/sessionStorage";
import "./App.css";
import { AppInstallPrompt } from "./components/AppInstallPrompt";

export const UserContext = React.createContext();

function App(props) {
  let history = useHistory();
  const [authError, setAuthError] = useState(null);

  // Subscribe to auth events
  useEffect(() => {
    const handler = async ({ payload }) => {
      switch (payload.event) {
        case "signIn":
          console.log("made it to app.js - signIn event");
          login(props);

          setTimeout(() => {
            history.push("/success");
          }, 1000);

          break;

        case "signIn_failure":
          console.log("signIn_failure in app.js", payload.data);
          Log.error(payload.data, "App Component - error caught on signIn");
          setAuthError(payload.data.message);
          break;
        case "signOut":
          console.log("sign out called!");
          //dispatch(push(routes.CLIENT_LOGOUT_REDIRECT));
          break;

        default:
          break;
      }
    };

    Hub.listen("auth", handler);

    //clean up any events
    return () => {
      Hub.remove("auth", handler);
    };
  }, []);

  async function handleLogout(url) {
    try {
      logout(url);
    } catch (e) {
      Log.error(e, "App Component: error caught logging out");
    }
  }

  const UserContextValue = {
    handleLogout: handleLogout,
    authError: authError,
    session: sessionStorage,
    setAuthError: setAuthError,
  };

  return (
    <div className={styles.App} id="App">
      <UserContext.Provider value={UserContextValue}>
        <Routes />
      </UserContext.Provider>
      <AppInstallPrompt/>
    </div>
  );
}

export default App;
