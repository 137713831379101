import Auth from "@aws-amplify/auth";
import Log from "./Log";
import { getCognitoUser } from "../utils/CognitoUtils";
import { createUser } from "../api/auth";
import { saveCredentialsToSession } from "../utils/sessionStorage";

export async function logout(url) {
  const previousRoute = sessionStorage.getItem("previousRoute");
  try {
    await Auth.signOut();
    localStorage.clear();
    sessionStorage.clear();

    sessionStorage.setItem("previousRoute", previousRoute || "");

    if (url && url.length) {
      window.location.href = url;
    }
  } catch (e) {
    Log.error(e, "Error dispatching: logout");
  }
}

export async function login(props) {
  try {
    const cognitoUserInfo = await getCognitoUser();
    console.log("await getCognitoUser: cognitoUserInfo", cognitoUserInfo);
    const { email, name, accessToken, refreshToken } = cognitoUserInfo;

    const response = await createUser(accessToken);

    console.log("response from create user:", response.payload);

    const { user, userCreated } = response.payload;

    let { username } = await Auth.currentAuthenticatedUser();

    console.log(" await Auth.currentAuthenticatedUser username", username);

    const userObject = {
      username: username,
      name: name,
      uuid: user.Id,
      email: user.Email,
      isNewUser: userCreated,
    };

    const tokenObject = {
      refreshToken,
      accessToken,
      email,
    };
    saveCredentialsToSession(tokenObject);
    Log.info("Opening deeplink to BBWorld");
    console.log("Opening deeplink to BBWorld");
    window.location.href = `bbworld://login?token=${accessToken}&refreshToken=${refreshToken}&cognitoName=${username}&email=${email}`;
  } catch (e) {
    Log.error(e, "Error dispatching: login");
    console.log("Error dispatching: login", e);
    if (e.response && e.response.status === 401) {
      Log.error(e, "Error dispatching: Unauthorized");
    } else {
      Log.error(e, "Error dispatching: Server Error");
    }
  }
}
