import React, { useEffect, Suspense } from "react";
import { Route } from "react-router-dom";
import styles from '../styles/Routes.module.scss'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons/faSpinner";

export default function AppliedRoute({ component: C, appProps, ...rest }) {
  useEffect(() => {
    //to bring to top of page since react-router remembers your scroll pos?
    window.scrollTo(0, 0);
  });
  return (
    <Route
      {...rest}
      render={(props) => (
        <Suspense fallback={<div style={{ height: "100vh", width: "100vw" }}>
        <div className={styles.container}>
          <div className={styles.headerItemsLoading}>
            <div>
              <img src='https://frontend-assets.buildbox.com/0_BBWorld_New_Logo_horizontal_light.png' alt='logo' />
              <div className={styles.loadingCircularContainer}>
                <div>
                  <FontAwesomeIcon className={styles.loadingIcon} icon={faSpinner} size="lg" spin/>
                  </div> 
              </div>
            </div>
          </div>
          </div>
      </div>}>
          <C {...props} {...appProps} />
        </Suspense>
      )}
    />
  );
}
