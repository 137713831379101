import API from "@aws-amplify/api";

/**
 * creates user and returns active subscription information
 * @param {token}: string
 * @param {email}: string
 */

export const createUser = (token) => {
  return new Promise((resolve, reject) => {
    API.post("devURL", "/webclientlogin", {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Token: token,
      },
      response: true, //needs this to get the whole response object
    })
      .then((res) => {
        //200 indicates a returning user

        if (res.status === 200) {
          resolve({ ...res.data, newUser: false });
        }
        //201 indicates a new registration so we need to hit GA
        else if (res.status === 201) {
          resolve({ ...res.data, newUser: true });
          //send registration event
        }
      })
      .catch((error) => {
        if (error.response) {
          reject(error.response.data.error);
        }
      });
  });
};

/**
 * Returns user information
 * @param {uuid}: string
 * @param {token}: string
 */
export const getUserInfo = ({ uuid, token }) => {
  return new Promise((resolve, reject) => {
    API.get("devURL", `/users/${uuid}`, {
      headers: {
        "Content-Type": "application/json",
        Token: token,
      },
      response: true,
    })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve(res.data);
        }
      })
      .catch((error) => {
        if (error.response) {
          reject(error.response.data.error);
        }
      });
  });
};
