import React, { lazy, Suspense }  from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import routes from "./constants/routes.json";
import AppliedRoute from "./components/AppliedRoute";

const Home = lazy(() => import("./pages/home/Home"))
const BitAcceptanceCriteria = lazy(() => import('./pages/acceptanceCriteria/BitAcceptanceCriteria'))
const NotFound = lazy(() => import("./pages/NotFound"))
const Login = lazy(() => import("./pages/Login"))
const LoginRedirect = lazy(() => import("./pages/LoginRedirect"))
const LogoutRedirect = lazy(() => import("./pages/LogoutRedirect"))
const SignInSuccess = lazy(() => import("./pages/SignInSuccess"))
const AppleAppSiteAssociation = lazy(() => import("./static/AppleAppSiteAssociation"))
const ClientAwaitingGoogleLogin = lazy(() => import("./pages/ClientAwaitingGoogleLogin"))
const ClientAwaitingFacebookLogin = lazy(() => import("./pages/ClientAwaitingFacebookLogin"))
const ClientAwaitingAppleLogin = lazy(() => import("./pages/ClientAwaitingAppleLogin"))
const ClientGoogleLogin = lazy(() => import("./pages/ClientGoogleLogin"))
const ClientFacebookLogin = lazy(() => import("./pages/ClientFacebookLogin"))
const ClientAppleLogin = lazy(() => import("./pages/ClientAppleLogin"))

export default function Routes({ appProps }) {
  return (
    <Switch>
      <AppliedRoute exact 
      path="/"
      appProps={appProps}
      component={Home}
      />
      <AppliedRoute exact 
      path={routes.BIT_ACCEPTANCE_CRITERIA}
      appProps={appProps}
      component={Home}
      />
      <AppliedRoute path={routes.LOGIN} exact component={Login} 
      appProps={appProps} />
      <AppliedRoute path={routes.LOGIN_REDIRECT} exact component={LoginRedirect} appProps={appProps}/>
      <AppliedRoute path={routes.LOGOUT_REDIRECT} exact component={LogoutRedirect} appProps={appProps}/>
      <AppliedRoute path={routes.SUCCESSFUL_SIGNIN} exact component={SignInSuccess} appProps={appProps}/>
      <AppliedRoute
        path={routes.APPLE_APP_SITE_ASSOCIATION}
        exact
        component={AppleAppSiteAssociation}
        appProps={appProps}
      />
      <AppliedRoute
        path={routes.CLIENT_AWAITING_GOOGLE_LOGIN}
        exact
        component={ClientAwaitingGoogleLogin}
        appProps={appProps}
      />
      <AppliedRoute
        path={routes.CLIENT_AWAITING_FACEBOOK_LOGIN}
        exact
        component={ClientAwaitingFacebookLogin}
        appProps={appProps}
      />
      <AppliedRoute
        path={routes.CLIENT_AWAITING_APPLE_LOGIN}
        exact
        component={ClientAwaitingAppleLogin}
        appProps={appProps}
      />
      <AppliedRoute
        path={routes.CLIENT_GOOGLE_LOGIN}
        exact
        component={ClientGoogleLogin}
        appProps={appProps}
      />
      <AppliedRoute
        path={routes.CLIENT_FACEBOOK_LOGIN}
        appProps={appProps}
        exact
        component={ClientFacebookLogin}
      />
      <AppliedRoute
        path={routes.CLIENT_APPLE_LOGIN}
        exact
        component={ClientAppleLogin}
        appProps={appProps}
      />
      
      {/* For BBWorld deeplink URLs on desktop, currently we have decided to redirect them to the homepage */}
      <Route path={routes.BBWORLD_DEEPLINK}>
        <Redirect to="/" />
      </Route>
          
      {/* Finally, catch all unmatched routes */}
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}
