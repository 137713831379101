import Log from "./Log";
import Auth from "@aws-amplify/auth";
import API from "@aws-amplify/api";

export const checkTokenExpiration = async (token) => {
  if (token) {
    try {
      const parseJwt = JSON.parse(atob(token.split(".")[1]));

      const { exp } = await parseJwt;

      // get current epoch time
      const currentTimeStamp = new Date().getTime() / 1000;

      if (currentTimeStamp >= exp) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      Log.error(error, "error caught in checkTokenExpiration");
      return null;
    }
  }
};

export const getCognitoUser = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.accessToken.jwtToken;
    const refreshToken = user.signInUserSession.refreshToken.token;
    const { email, name } = user.attributes;
    const userObject = {
      email,
      name,
      accessToken: token,
      refreshToken,
    };
    return userObject;
  } catch (e) {
    console.log("error getting cognito user", e);
  }
};

export const getRefreshedCognitoTokens = async (
  refreshToken,
  cognitoClientId,
  clientSecretHash
) => {
  return new Promise((resolve, reject) => {
    fetch(
      `https://cognito-idp.${process.env.REACT_APP_REGION}.amazonaws.com/`,
      {
        headers: {
          "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
          "Content-Type": "application/x-amz-json-1.1",
        },
        mode: "cors",
        cache: "no-cache",
        method: "POST",
        body: JSON.stringify({
          ClientId: cognitoClientId,
          AuthFlow: "REFRESH_TOKEN_AUTH",
          AuthParameters: {
            REFRESH_TOKEN: refreshToken,
            SECRET_HASH: clientSecretHash, // add client secret if there is one
          },
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        Log.info("data from get refresh cognito tokens", data);

        if (!data.AuthenticationResult) {
          reject(data);
        }

        resolve(data.AuthenticationResult.AccessToken);
      })
      .catch((error) => {
        Log.error(error, "error caught in while calling REFRESH_TOKEN_AUTH");
        reject(error);
      });
  });
};

export const refreshWebAccessToken = async () => {
  Log.info("", "refreshWebAccessToken called");

  const cognitoUser = await Auth.currentAuthenticatedUser();
  const currentSession = await Auth.currentSession();

  return new Promise((resolve, reject) => {
    cognitoUser.refreshSession(
      currentSession.refreshToken,
      (error, session) => {
        console.log("session", session);
        console.log("error", error);

        if (error) {
          Log.error(error, "error caught in while refreshing web client token");
          reject(error);
        }
        const accessToken = session.accessToken.jwtToken;
        console.log("accessToken", accessToken);

        resolve(accessToken);
      }
    );
  });
};

export const confirmAccount = (cognitoClientId, userName, confirmationCode) => {
  return new Promise((resolve, reject) => {
    API.post("devURL", "/confirmCognitoAccount", {
      body: {
        clientId: cognitoClientId,
        userName: userName,
        confirmationCode: confirmationCode,
      },
      headers: {
        "Content-Type": "application/json",
      },
      response: true,
    })
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getUserSessionTokens = async () => {
  try {
    const currentSession = await Auth.currentSession();

    if (currentSession) {
      return true;
    }

    return false;
  } catch (e) {
    Log.error(e, "error: getUserSessionTokens");
    throw e;
  }
};
