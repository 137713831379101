export const aws_config = {
  apiGateway: {
    REGION: process.env.REACT_APP_REGION,
    URL: process.env.REACT_APP_API_GATEWAY_URL,
  },
  cognito: {
    REGION: process.env.REACT_APP_REGION,
    USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
  },
  oauth: {
    domain: process.env.REACT_APP_COGNITO_DOMAIN_URL,
    scope: [
      "phone",
      "email",
      "profile",
      "openid",
      "aws.cognito.signin.user.admin",
    ],
    redirectSignIn: process.env.REACT_APP_REDIRECT_SIGN_IN_URL,
    redirectSignOut: process.env.REACT_APP_REDIRECT_SIGN_OUT_URL,
    responseType: "code",
  },
};
